

import { Link } from "react-router-dom";

//import pages
import { useHistory } from "react-router-dom";


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useState } from "react";
import { useEffect } from "react";
const MySwal = withReactContent(Swal)

const OnlinePaymentComponent = (state, props) => {


     const history = useHistory();

     let [check_authorized, set_authorized] = useState('unauthorized');

     //manage session
     const [user, setUser] = useState(null);
     const manage_session_url = `${window.url}/manage-session`;

     useEffect(() => {


          //send request to the server for manage session
          const token = localStorage.getItem('token')
          if( token ){
               const options_for_manage_session_request = {
                    method: 'GET',
               };
               fetch(`${manage_session_url}/${token}`,options_for_manage_session_request)
               .then( response => response.json() )
               .then( response => {
                    if( response.status == 'error' ){
                         localStorage.removeItem('token')
                         set_authorized('unauthorized');
                         history.push({
                              pathname: '/login',
                         });
                    }
                    if( response.status == 'success' ){
                         setUser(response.data)
                         localStorage.setItem('token',response.data.remember_token)
                         set_authorized('authorized');
                    }
     
               })
          }
          else{
               history.push("/login")
          }


     },[])


     return(
          <div className="id">

          </div>
     );
     
     
}

export default OnlinePaymentComponent;