import { useEffect, useState } from "react";

const LeftSidebarComponent = (props) => {

     const [user, setUser] = useState(null);

     useEffect(() => {
          setUser(props.user)

     },[])

     return(
          <div className="id">
               <div className="left-side">
                    <div className="profile-side">
                         <p className="mobile-no"><i className="fa fa-phone"></i> { user && user.phone ? user.phone : "N/A" }</p>
                         <p className="user-mail"><i className="fa fa-envelope"></i> { user && user.email ? user.email : "N/A" }</p>
                    </div>

               </div>
          </div>
     );
}

export default LeftSidebarComponent;