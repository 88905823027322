

import { Link } from "react-router-dom";

//import pages
import MobileMenu from "../../Include/MobileMenu";
import Footer from "../../Include/Footer";
import Loading from "../../Include/Loading";
import { useHistory } from "react-router-dom";
import Header from "../../Include/Header";


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useState } from "react";
const MySwal = withReactContent(Swal)

const OtpComponent = (state, props) => {


     const history = useHistory();

     //check authentication start
     const token = localStorage.getItem('token')
     if( token ){
          history.push("/dashboard");
      }
     //check authentication end


     
     //verifyUser functionality start
     const [code, setCode] = useState('');
     const [ errors, set_error ] = useState(null)

     function verifyUser(){
          let customer = localStorage.getItem("customer")
          const loading = document.getElementById("loading-wraper")

          if( customer ){

               if( code && code.length == 6 ){

                    loading.style.display = 'block'
                    let parse_customer_data = JSON.parse(customer)
                    let verify_url = `${window.url}/auth/verify`;
     
                    const formData = new FormData();
                    formData.append('id', parse_customer_data.id);
                    formData.append('code', code);
     
                    const options = {
                         method : 'POST',
                         body: formData
                    }
     
                    fetch(verify_url,options)
                    .then( response => response.json() )
                    .then( response => {
                         loading.style.display = "none"
     
                         MySwal.fire({
                              title : `${response.status}`,
                              text : `${response.message}`,
                         })
                         
                         if( response.status == 'success' ){
                              localStorage.setItem('token',response.data.remember_token)
          
                              localStorage.removeItem("step_one_data")
                              localStorage.removeItem("step_two_data")
                              localStorage.removeItem("contact_data")
                              localStorage.removeItem("services")
                              localStorage.removeItem("customer")
          
                              history.push('/dashboard')
                         }
                         
                         
                    })
                    .catch( error => {
                         loading.style.display = "none"
                         console.log(error)
                         
                    })
               }
               else{
                    MySwal.fire({
                         title : "WARNING",
                         text : 'Invalid code found',
                    })
               }
               
          }
          else{
               MySwal.fire({
                    title : "WARNING",
                    text : 'No user found',
               })
          }
     }
     //verifyUser functionality end

     
     //resendOtp functionality start
     function resendOtp(){
          let customer = localStorage.getItem("customer")
          const loading = document.getElementById("loading-wraper")

          console.log("hi")
          loading.style.display = 'block'
          if( customer ){
               let parse_customer_data = JSON.parse(customer)
               let resend_otp_url = `${window.url}/auth/resend-code`;

               const formData = new FormData();
               formData.append('id', parse_customer_data.id);
               const options = {
                    method : 'POST',
                    body: formData
               }

               fetch(resend_otp_url,options)
               .then( response => response.json() )
               .then( response => {
                    loading.style.display = "none"

                    MySwal.fire({
                         title : `${response.status}`,
                         text : `${response.message}`,
                    })

                    if(response.status == 'success'){
                         localStorage.setItem('customer',JSON.stringify(response.customer))
                    }
                    
               })
               .catch( error => {
                    loading.style.display = "none"
                    console.log(error)
                    
               })
               
          }
          else{
               MySwal.fire({
                    title : "WARNING",
                    text : 'No user found',
               })
          }
     }
     //resendOtp functionality end
        

     return(
          <div className="id">

               {/* loading */}
               <Loading></Loading>

               <MobileMenu ></MobileMenu>

               <div className="page-wrapper">

                   <Header ></Header>

                   <div className="user-card round5">
                         <div className="login-box">
                              <div className="card-title">
                                   <h4>Enter OTP</h4>
                                   <small>We sent you an otp in you email address.</small>
                              </div>
                              <div className="form-group">
                                   <input type="text" name="code" className="code" placeholder="xxxxxx"
                                   onChange={
                                        e => {
                                             setCode(e.target.value)
                                        }
                                   }
                                   />
                                   {
                                        errors &&
                                        <small
                                        className="form_error"
                                        >
                                        {errors.code}
                                        </small>
                                   }
                              </div>
                              <div className="form-group">
                                   <input type="button" name="verify" value="Verify" onClick={verifyUser} className="login" />
                                   <small id="resend-otp" onClick={resendOtp}>Resend otp</small>
                              </div>

                         </div>

                         {/* Card Footer */}

                         <div className="footer">
                              <span>or </span><Link className="toggle-link" to="/login">Sign in</Link>
                         </div>
                    </div>

                   <Footer ></Footer>
                    
               </div>

          </div>
     );
     
     
}

export default OtpComponent;